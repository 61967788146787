<template>
    <div class="login-screen">
        <div class="left-login-area flex flex-col justify-center items-center">
            <idc-logo class="d-block mb-4" width="100%" height="48px" color="white"/>
<!--            <h3 class="text-center mb-3 text-white">-->
<!--                Bienvenido-->
<!--            </h3>-->
        </div>
        <div class="right-login-area flex-col container mx-auto flex p-3">
            <h3 class="text-center mb-3 font-bold text-lg mb-14">
              Por favor ingrese su nueva contraseña
            </h3>
            <form class="w-full xl:w-1/2">
                <a-input
                    label="Contraseña"
                    id="password"
                    type="password"
                    :error="error('password', { attribute: 'contraseña' })"
                    v-model="guest.password" />

                <div class="flex flex-col items-center mt-4">

                    <a-button :loading="loading" submit class="w-full" @click="passwordConfirm(guest)">
                        Confirmar
                    </a-button>

                    <div class="flex items-center justify-between space-x-4">
                        <span class="h-0.5 bg-gray-500 flex-grow inline-block w-32"/>
                        <h1 class="text-2xl text-gray-500 py-8">O</h1>
                        <span class="h-0.5 bg-gray-500 flex-grow inline-block w-32"/>
                    </div>

                    <router-link class="text-primary font-bold" :to="{ name: 'empty' }">
                        Iniciar sesión
                    </router-link>
                </div>
            </form>
        </div>
        <div class="fixed flex justify-center items-center flex-col inset-0 bg-white bg-opacity-50 backdrop-blur-15" v-if="!this.$route.query.token">
            <div class="container mx-auto text-center">
                <h1 class="text-2xl">
                    Los recursos necesarios para confirmar su cuenta no fueron encontrados.
                </h1>
                <h2 class="text-lg mt-2">
                    Póngase en contacto con el administrador del sistema para generar un nuevo correo electrónico.
                </h2>
            </div>

            <div class="flex items-center justify-between space-x-4">
                <span class="h-0.5 bg-gray-500 flex-grow inline-block w-32"/>
                <h1 class="text-2xl text-gray-500 py-8">O</h1>
                <span class="h-0.5 bg-gray-500 flex-grow inline-block w-32"/>
            </div>

            <router-link class="text-primary font-bold" :to="{ name: 'empty' }">
                Iniciar sesión
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import IdcLogo from '@/components/ui/IDCLogo.vue';
export default {
    data: () => ({
        guest: {
            email: '',
            password: '',
            token: null
        },
        lang: 'es'
    }),
    watch: {
        lang(val) {
            this.translate(val)
        }
    },
    computed: {
        ...mapGetters({
            error: 'error',
            hasError: 'hasError'
        }),
        ...mapState({
            loading: state => state.session.loading
        })
    },
    methods: {
        ...mapActions({
            translate: 'translate',
            removeError: 'removeError',
            passwordConfirm: 'session/passwordConfirm'
        }),
        handle() {
            this.passwordConfirm(this.guest)
                .then(() => {
                    this.$router.push({ name: 'empty' })
                })
        }
    },
    components: {
        IdcLogo
    },
    mounted() {
        this.guest.token = this.$route.query?.token
        this.guest.email = this.$route.query?.email
    }
}
</script>

<style scoped>
.login-screen {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.login-screen > .left-login-area, .login-screen > .right-login-area {
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.login-screen > .left-login-area {
    background: #4D4F5C;
}

.login-screen > .right-login-area {
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px) {
    .login-screen {
        grid-template-columns: 1fr;
    }

    .left-login-area {
        display: none !important;
    }
}
</style>
